import { useBlockChainBalance } from 'features/approved-balance/hooks/use-blockchain-balance'
import { useGetUserLevel } from './use-get-user-level'
import { useLoadingOverlay } from './use-loading-overlay'
import { useOffline } from './use-offline'
import { useSmartContracts } from './use-smart-contracts'
import { useUserMetadata } from './use-user-metadata'
import { useVerifyUser } from './use-verify-user'

export const useAppHookParent = () => {
  useVerifyUser()
  useOffline()
  useUserMetadata()
  useLoadingOverlay()
  useBlockChainBalance()
  useGetUserLevel()
  useSmartContracts()
}
