import { wagmiConfig } from 'app/wagmi-config'
import { useGetSmartContract } from 'shared/hooks/use-smart-contracts'
import { Web3Adress } from 'shared/types'
import { useAccount } from 'wagmi'
import { deposits, withdraw } from '../calls'

export const useTreasury = () => {
  const account = useAccount({
    config: wagmiConfig
  })

  const { smartContractAddress } = useGetSmartContract('Treasury')

  if (!account.address) return

  const loadTreasuryBalance = () =>
    deposits(account.address as Web3Adress, smartContractAddress)
  const claimTreasury = (amount: string) =>
    withdraw(amount, smartContractAddress)

  return {
    claimTreasury,
    loadTreasuryBalance
  }
}
