import { useCallback, useEffect, useLayoutEffect } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import { GET_SMART_CONTRACTS } from 'api/general/get-smart-contracts'
import { smartContractsVar } from 'shared/store/contracts'
import { globalOverlaySmartContractsLoadingVar } from 'shared/store/global-overlay-state-store'
import { notificationStateVar } from 'shared/store/notification'
import { Web3Adress } from 'shared/types'
import { zeroAddress } from 'viem'

type ContractEntryName =
  | 'OneVsOne'
  | 'UpDown'
  | 'FrontHelper'
  | 'Dataverifier'
  | 'Bullseye'
  | 'RevenueBank'
  | 'USDC'
  | 'XyroToken'
  | 'Treasury'
  | 'Setup'

export const useSmartContracts = () => {
  const [getSmartContracts, { loading }] = useLazyQuery(GET_SMART_CONTRACTS)

  const handleGetSmartContracts = useCallback(async () => {
    try {
      const res = await getSmartContracts()

      const smartContractEntries = res.data?.getSmartContracts || null

      if (!smartContractEntries) return

      smartContractsVar(smartContractEntries)
    } catch {
      notificationStateVar({
        isOpen: true,
        type: 'error',
        title: 'Error! Smart contract addresses were not fetched'
      })
    }
  }, [getSmartContracts])

  useEffect(() => {
    globalOverlaySmartContractsLoadingVar(loading)
  }, [loading])

  useLayoutEffect(() => {
    handleGetSmartContracts()
  }, [handleGetSmartContracts])
}

export const useGetSmartContract = (contractType: ContractEntryName) => {
  const smartContracts = useReactiveVar(smartContractsVar)

  const smartContractEntry = smartContracts.find(
    c => c.contractName === contractType
  )

  const smartContractAddress = (smartContractEntry?.contracts?.[0]
    ?.contractAddress || zeroAddress) as Web3Adress

  return { smartContractEntry, smartContractAddress }
}
